import { FC, useEffect, useMemo, useState } from "react"
import { DatePicker, Form, Input, Select, Checkbox, Radio, Switch } from "antd"
import moment from "moment"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"

import { DistributionType, FullRaffle, HandleType, UpdateRaffle, UpdateRaffleLive, UpdateRaffleUpcoming } from "../../store/types"
import s from "../styles.module.css"
import { PrizeSection } from "../PrizeSection"
import { notifications } from "notifications"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { FormElement, SubmitCancelButtons, UploadComponent } from "components"
import GeoPickerElement from "components/geoPicker"

const utmParams = [
  "source",
  "medium",
  "campaign",
  "extra",
  "content",
  "term",
]

const upcomingOnlyEditableFields: (keyof UpdateRaffleUpcoming)[] = [
  "platforms",
  "rules",
  "prizeSection",
  "tierLevel",
  "tierName",
  "countryCodes",
  "premiumTypes",
  "periodStart",
  "utmData",
]
const liveEditableFields: (keyof UpdateRaffleLive)[] = [
  "title",
  "subtitle",
  "desktopListImagesUrl",
  "desktopMainImagesUrl",
  "mobileListImagesUrl",
  "mobileMainImagesUrl",
  "distributionType",
  "isActive",
  "periodFinish",
]
const upcomingEditableFields = [...upcomingOnlyEditableFields, ...liveEditableFields] as (keyof FullRaffle)[]

const getRaffleStatus = (raffle: FullRaffle) => {
  const periodStart = new Date(raffle.periodStart)
  const periodEnd = new Date(raffle.periodFinish)
  if (Date.now() < periodStart.getTime()) {
    return "UPCOMING"
  } else if (Date.now() < periodEnd.getTime()) {
    return "ACTIVE"
  }

  return "FINISHED"
}

const getEditableFields = (raffle?: FullRaffle) => {
  if (!raffle) return null
  const status = getRaffleStatus(raffle)

  return status === "FINISHED" ? [] : status === "ACTIVE" ? liveEditableFields : upcomingEditableFields
}

const getPatchFromUpdate = (raffle: FullRaffle, old: FullRaffle): UpdateRaffle => {
  const keysToKeep = getEditableFields(old)

  if (!keysToKeep) return {} as UpdateRaffle

  const oldKeys = Object.keys(old)
  const newKeys = Object.keys(raffle)
  const allKeys = Array.from(new Set(...oldKeys, ...newKeys))
  const changedKeys = allKeys.filter(key => JSON.stringify(raffle[key]) !== JSON.stringify(old[key]))

  // check if unallowed key changed
  for (const key of changedKeys) {
    if (!keysToKeep.includes(key as keyof FullRaffle)) {
      notifications.error(`Can't update '${key}', raffle status is: ${status}`)
      throw new Error()
    }
  }

  const filtered = Object.entries(raffle).filter(([key]) => keysToKeep.includes(key as keyof FullRaffle))

  return Object.fromEntries(filtered) as unknown as UpdateRaffle
}

export const RaffleEdit: FC<{
  editType: "create" | "edit";
  closeModal: () => void;
}> = observer(({ editType, closeModal }) => {
  const {
    dataStore: {
      rafflesStore: { selectedElement },
      rafflesStore,
      tierStore,
      subscriptionStore,
    },
  } = useStore()
  const [form] = Form.useForm()

  const isEdit = editType === "edit"

  useEffect(() => {
    if (tierStore.tiers.length === 0) {
      tierStore.getData()
    }
    if (subscriptionStore?.subscriptions?.length === 0) {
      subscriptionStore.getData()
    }
  }, [subscriptionStore, tierStore])

  const [devices, setDevices] = useState(isEdit ? selectedElement?.platforms || [] : [])
  const [isActive, setisActive] = useState(isEdit ? selectedElement?.isActive : true)

  const [paragraps, setparagraps] = useState(isEdit ? selectedElement.prizeSection && selectedElement.prizeSection[0] : undefined)

  const [desktopListImagesUrl, setdesktopListImagesUrl] = useState(isEdit ? selectedElement?.desktopListImagesUrl || "" : "")
  const [desktopMainImagesUrl, setdesktopMainImagesUrl] = useState(isEdit ? selectedElement?.desktopMainImagesUrl || "" : "")
  const [mobileListImagesUrl, setmobileListImagesUrl] = useState(isEdit ? selectedElement?.mobileListImagesUrl || "" : "")
  const [mobileMainImagesUrl, setmobileMainImagesUrl] = useState(isEdit ? selectedElement?.mobileMainImagesUrl || "" : "")

  const [distrType, setDistrType] = useState<DistributionType>(isEdit ? selectedElement?.distributionType || "AUTOMATED" : "AUTOMATED")
  const [handleType, sethandleType] = useState<HandleType>(isEdit ? selectedElement?.handleType || "CUSTOM" : "CUSTOM")
  const [countryRestriction, setcountryRestriction] = useState<boolean>(isEdit
    ? selectedElement?.countryCodes
      ? selectedElement?.countryCodes?.length > 0
      : false
    : false)
  const [participantsLimit, setparticipantsLimit] = useState<boolean>(isEdit ? !!selectedElement?.participantsLimit : false)
  // eslint-disable-next-line max-len
  const [premiumRestriction, setpremiumRestriction] = useState<boolean>(isEdit ? !!selectedElement?.premiumTypes && selectedElement?.premiumTypes?.length > 0 : false)
  const [premiumRestrictionArr, setpremiumRestrictionArr] = useState(isEdit ? selectedElement?.premiumTypes || undefined : undefined)
  const [partsLimitVal, setpartsLimitVal] = useState(isEdit ? selectedElement?.participantsLimit || undefined : undefined)
  const [tierLevelCurr, settierLevelCurr] = useState(isEdit ? selectedElement?.tierLevel || "" : "")

  const [utmData, setUtmData] = useState<Record<string, string> | null>(isEdit ? selectedElement?.utmData || null : null)

  const [startDate, setStartDate] = useState(isEdit ? (selectedElement?.periodStart ? moment(selectedElement?.periodStart) : "") : "")
  const [endDate] = useState(isEdit ? (selectedElement?.periodFinish ? moment(selectedElement?.periodFinish) : "") : "")

  useEffect(() => {
    setdesktopMainImagesUrl(selectedElement?.desktopMainImagesUrl || "")
    setdesktopListImagesUrl(selectedElement?.desktopListImagesUrl || "") //
    setmobileMainImagesUrl(selectedElement?.mobileMainImagesUrl || "")
    setmobileListImagesUrl(selectedElement?.mobileListImagesUrl || "")
    setparagraps(selectedElement?.prizeSection ? selectedElement?.prizeSection[0] : "" || "")
    setcountryRestriction(selectedElement?.countryCodes ? selectedElement?.countryCodes?.length > 0 : false)

    form.setFieldsValue({
      ...selectedElement,
      startDate:
        selectedElement?.handleType === "AUTOMATED" && moment(selectedElement?.periodStart),
      period: Math.abs(moment
        .duration(moment(selectedElement?.periodFinish).diff(selectedElement?.periodStart))
        .asHours()),
    })
  }, [selectedElement, form])

  useEffect(() => {
    if (tierLevelCurr.length === 0) {
      settierLevelCurr(tierStore.tiers.find(item => item.name === "Beginner")?.id || "")
    }
  }, [tierStore.tiers, tierLevelCurr])

  const setPlatform = (e: CheckboxChangeEvent) => {
    if (e.target.value === "all") {
      if (e.target.checked) {
        setDevices(["DESKTOP", "ANDROID", "IOS"])
      } else {
        setDevices([])
      }
    } else {
      if (e.target.checked) {
        const newDev = devices.concat(e.target.value)
        setDevices(newDev)
      } else {
        const newDev = devices.filter(item => item !== e.target.value)
        setDevices(newDev)
      }
    }
  }

  const onFinishHandler = async(data: FullRaffle & { countryCodes: string[]; date: Date[]; startDate: string; period: number }) => {
    if (devices.length === 0) {
      return notifications.error("Please select minimum one platform")
    }
    if (paragraps?.length === 0) {
      return notifications.error("Please input prizes")
    }
    if (desktopListImagesUrl.length === 0) {
      return notifications.error("Please select Desktop List Image")
    }
    if (desktopMainImagesUrl.length === 0) {
      return notifications.error("Please select Desktop Main Image")
    }
    if (mobileListImagesUrl.length === 0) {
      return notifications.error("Please select Mobile List Image")
    }
    if (mobileMainImagesUrl.length === 0) {
      return notifications.error("Please select Mobile Main Image")
    }

    const e: FullRaffle = {
      title: data.title,
      subtitle: data.subtitle,
      platforms: devices,

      desktopListImagesUrl: desktopListImagesUrl,
      desktopMainImagesUrl: desktopMainImagesUrl,
      mobileListImagesUrl: mobileListImagesUrl,
      mobileMainImagesUrl: mobileMainImagesUrl,

      prizeSection: ([] as string[]).concat(paragraps ? paragraps : ""),
      rules: ([] as string[]).concat(data.rules ? data.rules : ""),
      handleType: data.handleType,
      distributionType: data.distributionType,
      uniqueUserLimit: data.uniqueUserLimit ? +data.uniqueUserLimit : isEdit ? null : undefined,
      tierLevel: tierLevelCurr,
      tierName: tierStore.tiers.find(item => item.id === tierLevelCurr)?.name || "",
      countryCodes:
        countryRestriction && data.countryCodes?.length > 0
          ? data.countryCodes
          : isEdit
            ? []
            : [],
      premiumTypes:
        premiumRestrictionArr && premiumRestrictionArr.length > 0
          ? premiumRestrictionArr
          : isEdit
            ? []
            : [],
      utmData: utmData || undefined,
      participantsLimit: participantsLimit ? partsLimitVal || undefined : isEdit ? null : undefined,
      isActive: isEdit ? data.isActive : undefined,
      periodStart:
        data.handleType === "CUSTOM"
          ? moment(data.date[0])?.seconds(0)?.milliseconds(0)?.toISOString()
          : moment(data.startDate)?.seconds(0)?.milliseconds(0)?.toISOString(),
      periodFinish:
        data.handleType === "CUSTOM"
          ? moment(data.date[1])?.seconds(0)?.milliseconds(0)?.toISOString()
          : moment(data.startDate)
            ?.seconds(0)
            ?.milliseconds(0)
            .add(+data.period, "hours")
            .toISOString(),
    }

    rafflesStore.funcAfterSuccess = async() => {
      closeModal()
      await rafflesStore.getData()
    }

    if (isEdit) {
      const updates = getPatchFromUpdate(e, selectedElement)
      await rafflesStore.updateRaffle(updates)
    } else {
      await rafflesStore.createRaffle(e)
    }
  }

  const editableKeys = useMemo(() => getEditableFields(selectedElement), [selectedElement])

  const isDisabled = (key: keyof FullRaffle) => {
    if (editType === "create") return false
    if (!editableKeys) return false

    return !editableKeys.includes(key)
  }

  return (
    <Form onFinish={onFinishHandler} style={{ marginTop: 16 }} id="createRaffle">
      <Form.Item
        hasFeedback
        label="Title"
        name="title"
        initialValue={isEdit ? selectedElement?.title : ""}
        rules={[
          {
            required: true,
            message: "Please input title",
          },
        ]}
      >
        <Input className={s.defaultBorder} maxLength={50} disabled={isDisabled("title")} />
      </Form.Item>

      <Form.Item
        hasFeedback
        label="Subtitle"
        name="subtitle"
        initialValue={isEdit ? selectedElement?.subtitle : ""}
        rules={[
          {
            required: true,
            message: "Please input subtitle",
          },
        ]}
      >
        <Input
          className={s.defaultBorder}
          maxLength={50}
          disabled={isDisabled("subtitle")}
        />
      </Form.Item>

      <Form.Item
        label="Platform"
        initialValue={devices}
        valuePropName="checked"
        required
      >
        <Checkbox
          value="all"
          onChange={setPlatform}
          checked={devices.length === 3}
          disabled={isDisabled("platforms")}
        >
          All
        </Checkbox>
        <Checkbox
          onChange={setPlatform}
          value="DESKTOP"
          checked={devices.includes("DESKTOP")}
          disabled={isDisabled("platforms")}
        >
          Desktop
        </Checkbox>
        <Checkbox
          onChange={setPlatform}
          value="ANDROID"
          checked={devices.includes("ANDROID")}
          disabled={isDisabled("platforms")}
        >
          Android
        </Checkbox>
        <Checkbox
          onChange={setPlatform}
          value="IOS"
          checked={devices.includes("IOS")}
          disabled={isDisabled("platforms")}
        >
          IOS
        </Checkbox>
      </Form.Item>
      <div>
        <Switch
          defaultChecked={(countryRestriction as boolean) || false}
          disabled={isDisabled("countryCodes")}
          onChange={setcountryRestriction}
          style={{
            position: "absolute",
            left: "33%",
            zIndex: 1,
          }}
        />
        <GeoPickerElement
          label="Country Restriction"
          name="countryCodes"
          initialValue={selectedElement?.countryCodes || undefined}
          style={{ marginLeft: "20%", width: "80%" }}
          disabled={!countryRestriction || isDisabled("countryCodes")}
        />
      </div>
      <div>
        <Switch
          defaultChecked={(participantsLimit as boolean) || false}
          onChange={() => {
            if (!participantsLimit) {
              setpartsLimitVal(1)
            } else {
              setpartsLimitVal(undefined)
            }
            setparticipantsLimit(!participantsLimit)
          }}
          style={{
            position: "absolute",
            left: "33%",
            zIndex: 1,
          }}
          disabled={isDisabled("participantsLimit")}
        />
        <Form.Item label="Places Limitation" className="withSwitchers">
          <Input
            type="number"
            disabled={!participantsLimit || isDisabled("participantsLimit")}
            style={{ marginLeft: "20%", width: "80%" }}
            value={partsLimitVal}
            onChange={e => setpartsLimitVal(+e.target.value)}
            min={1}
          />
        </Form.Item>
      </div>

      <div>
        <Switch
          defaultChecked={(premiumRestriction as boolean) || false}
          onChange={() => {
            if (!premiumRestriction) {
              setpremiumRestrictionArr(["PREMIUM", "PREMIUM_PLUS", "PREMIUM_ELITE"])
            } else {
              setpremiumRestrictionArr(undefined)
            }
            setpremiumRestriction(!premiumRestriction)
          }}
          style={{
            position: "absolute",
            left: "33%",
            zIndex: 1,
          }}
          disabled={isDisabled("premiumTypes")}
        />
        <Form.Item label="Premium Limitation" className="withSwitchers">
          <Select
            disabled={!premiumRestriction || isDisabled("premiumTypes")}
            mode="tags"
            style={{ marginLeft: "20%", width: "80%" }}
            value={premiumRestrictionArr}
            onChange={setpremiumRestrictionArr}
          >
            {["PREMIUM", "PREMIUM_PLUS", "PREMIUM_ELITE"].map((item, id) => {
              return (
                <Select.Option value={item} key={id}>
                  {item.replace("_", " ")}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", marginBottom: 24, gap: 8 }}>
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <span style={{ flexGrow: 1 }}>
          UTM Restrictions
          </span>
          <Switch
            checked={!!utmData}
            defaultChecked={(premiumRestriction as boolean) || false}
            onChange={e => {
              if (!e) {
                setUtmData(null)
              } else {
                setUtmData({})
              }
            }}
            disabled={isDisabled("utmData")}
          />
        </div>
        {!!utmData && (
          utmParams.map(param => (
            <div
              key={param}
              style={{ display: "flex", gap: 16, width: "100%", alignItems: "center" }}
            >
              <b style={{ width: "16rem" }}>
                {param}:
              </b>
              <Input
                placeholder="Restriction value"
                value={utmData[param] || ""}
                onChange={e => {
                  setUtmData(old => {
                    if (!old) return old
                    const val = e.target.value
                    if (val) return { ...old, [param]: val }
                    const { [param]: _, ...next } = old

                    return next
                  })
                }}
                disabled={isDisabled("utmData")}
              />
            </div>
          ))
        )}
      </div>

      <Form.Item
        label="Tickets Limit Per User:"
        name="uniqueUserLimit"
        initialValue={isEdit ? selectedElement?.uniqueUserLimit : 5}
      >
        <Input
          type="number"
          min={1}
          disabled={isDisabled("uniqueUserLimit")}
        />
      </Form.Item>
      <Form.Item label="Tier limitations:">
        <Select
          value={tierLevelCurr}
          onChange={e => settierLevelCurr(e)}
          disabled={isDisabled("tierLevel")}
        >
          {tierStore.tiers.map((item, id) => {
            return (
              <Select.Option key={id} value={item.id}>
                {item.name.toUpperCase()}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Raffle Type:" name="handleType" initialValue={handleType}>
        <Radio.Group
          onChange={e => sethandleType(e.target.value)}
          style={{ display: "flex", justifyContent: "space-between" }}
          disabled={isEdit}
        >
          <Radio value="CUSTOM">Custom</Radio>
          <Radio value="AUTOMATED">Automated</Radio>
        </Radio.Group>
      </Form.Item>

      {handleType === "CUSTOM" ? (
        <Form.Item
          name="date"
          initialValue={startDate && endDate ? [moment(startDate), moment(endDate)] : ""}
          rules={[
            {
              required: true,
              message: "Please Select Dates!",
            },
          ]}
          className="raffleDatePicker"
        >
          <DatePicker.RangePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            style={{ width: "100%" }}
            disabledDate={current => {
              return moment().subtract(1, "days") > current
            }}
            disabled={
              [isDisabled("periodStart"), isDisabled("periodFinish")]
            }
          />
        </Form.Item>
      ) : (
        <div style={{ paddingLeft: "10%", display: "flex", flexFlow: "column" }}>
          <Form.Item
            label="Start Date"
            name="startDate"
            style={{ width: "100%", gridTemplateColumns: "1fr 1fr" }}
            initialValue={startDate ? moment(startDate) : ""}
            rules={[
              {
                required: true,
                message: "Select start date",
              },
            ]}
          >
            <DatePicker
              disabledDate={current => {
                return moment().subtract(1, "days") > current
              }}
              value={moment(startDate)}
              onChange={e => setStartDate(e?.toDate() as any)}
              showTime
              format="YYYY-MM-DD HH:mm"
              placeholder="Select Start Date"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            initialValue={Math.round(Math.abs(moment
              .duration(moment(selectedElement?.periodFinish).diff(selectedElement?.periodStart))
              .asHours()))}
            label="Period (every X hours)"
            name="period"
            style={{ gridTemplateColumns: "1fr 1fr" }}
          >
            <Input type="number" min={1} />
          </Form.Item>
        </div>
      )}

      <Form.Item label="Prizes Distribution:" name="distributionType" initialValue={distrType}>
        <Radio.Group
          onChange={e => setDistrType(e.target.value)}
          style={{ display: "flex", justifyContent: "space-between" }}
          disabled={isDisabled("distributionType")}
        >
          <Radio value="AUTOMATED">Automated</Radio>
          {handleType !== "AUTOMATED" && <Radio value="MANUAL">Manual</Radio>}
        </Radio.Group>
      </Form.Item>

      <FormElement
        label="Rules section"
        name="rules"
        initialValue={isEdit ? selectedElement?.rules?.join(";") : ""}
        rules={[
          {
            required: true,
            message: "Please input Rules separated by ;",
          },
        ]}
        type="text"
        props={{
          className: s.defaultBorder,
          disabled: isDisabled("rules"),
          maxLength: 2000,
        }}
      />

      <PrizeSection setparagraps={setparagraps} data={paragraps} disabled={isDisabled("prizeSection")} />

      <div className={s.containerUploadImages}>
        <UploadComponent
          image={desktopListImagesUrl}
          setImage={setdesktopListImagesUrl}
          placeholder="Desktop List"
          title="Desktop List"
          maxSize={1}
          disabled={isDisabled("desktopListImagesUrl")}
        />
        <UploadComponent
          image={desktopMainImagesUrl}
          setImage={setdesktopMainImagesUrl}
          placeholder="Desktop Main"
          title="Desktop Main"
          maxSize={1}
          disabled={isDisabled("desktopMainImagesUrl")}
        />
        <UploadComponent
          image={mobileListImagesUrl}
          setImage={setmobileListImagesUrl}
          placeholder="Mobile List"
          title="Mobile List"
          maxSize={1}
          disabled={isDisabled("mobileListImagesUrl")}
        />
        <UploadComponent
          image={mobileMainImagesUrl}
          setImage={setmobileMainImagesUrl}
          placeholder="Mobile Main"
          title="Mobile Main"
          maxSize={1}
          disabled={isDisabled("mobileMainImagesUrl")}
        />
      </div>
      {isEdit && (
        <Form.Item
          label="Is Active?"
          valuePropName="checked"
          name="isActive"
          initialValue={isActive}
        >
          <Checkbox
            checked={isActive}
            onChange={() => setisActive(!isActive)}
            disabled={isDisabled("isActive")}
          />
        </Form.Item>
      )}
      <SubmitCancelButtons onClose={closeModal} isLoading={rafflesStore.isLoading} />
    </Form>
  )
})
